html,body,#root{
  background: #F5F9FB !important;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}
* {
  text-underline-offset: 10px;
  box-sizing: border-box;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}


@font-face {
  font-family: 'ABC-times-web';
  src: url('/public/ABC.Times_web_Ver.36.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ABC-Richard-Web-Nadeem';
  src: url('/public/ABC.Times_web_Ver.36.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'teshrin';
  src: url('/public/teshrin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'quran';
  src: url('/public/Quran.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: roman;
  src: url('./fonts/TimesNewRoman.ttf') format("truetype");
}

@font-face {
  font-family: ArabicEqu;
  src: url("./fonts/GmMath.ttf") format("truetype");
}

/* arabic */
@font-face {
  font-family: 'Droid';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./fonts/RrQKbpV-9Dd1b1OAGA6M9PkyDuVBeN2DHV2mLXgzTrc.cb7869ad56bfe2b36ceb.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}

/* arabic */
@font-face {
  font-family: 'Droid-Naskh-Regular';
  font-weight: 400;
  src: url(./fonts/RrQKbpV-9Dd1b1OAGA6M9PkyDuVBeN2DHV2mLXgzTrc.cb7869ad56bfe2b36ceb.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}


/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/Almarai-Light.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Almarai-Regular.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/Almarai-Bold.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./fonts/Almarai-ExtraBold.ttf) format("truetype");
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-thumb {
  background: #195497;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



div[dir=rtl],*[dir=rtl],p[dir=rtl]{
  font-family: 'Droid';
}

html[rtl] p{
  font-family: 'Droid' !important;
}

html{
  font-family: 'Almarai';
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


 
u {
  text-underline-position: auto;
  /* font-weight: bolder; */
}

img{
  display: inline-block;
}



#quest-font-teshrin p{
  font-family: 'teshrin' !important;
}
#quest-font-times p{
  font-family: 'ABC-times-web' !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
